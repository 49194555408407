<template>
    <sf-section
        v-if="filteredEntries && filteredEntries.length > 0"
        class="section--title-lg"
    >
        <template v-slot:title>
            {{ title || $t('title') }}
        </template>

        <template v-slot:default>
            <sf-discover-stream
                :entries="filteredEntries"
                :global-entries="entry.streamGlobalEntries"
            />
        </template>
    </sf-section>
</template>

<script>
import SfSection from '~/patterns/organisms/section/section';
import SfDiscoverStream from '~/patterns/organisms/discover-stream/discover-stream';
import executeCraftQuery from '~/graphql';
import discoverEntriesQuery from '~/graphql/queries/discover/discoverEntries.graphql';

export default {
    name: 'RelatedItems',

    components: {
        SfSection,
        SfDiscoverStream
    },

    props: {
        title: {
            type: String,
            default: null
        },

        entry: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            entries: null,
            limit: 6
        };
    },

    async fetch() {
        const variables = {
            limit: this.limit,
            showInStream: true
        };

        if (this.tagIds && this.tagIds.length > 0) {
            variables.tagIds = this.tagIds;
        }

        const entriesResponse = await executeCraftQuery.call(this, discoverEntriesQuery, variables);

        // Set discover overview entries
        if (entriesResponse && entriesResponse.data && entriesResponse.data.entries && entriesResponse.data.entries.length > 0) {
            this.entries = entriesResponse.data.entries;
        }
    },

    computed: {
        tagIds() {
            return this.entry.tags.map(tag => tag.id);
        },

        filteredEntries() {
            if (!this.entries || !this.entries.length) {
                return;
            }

            return this.entries.filter(entry => entry.id !== this.entry.id);
        }
    }
};
</script>

<i18n>
{
    "nl": {
        "title": "Ontdek ook"
    },
    "en": {
        "title": "Discover more"
    }
}
</i18n>
