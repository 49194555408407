export function formatSpecificationData(grant) {
    if (!grant || !grant.specifications || !grant.specifications.length > 0) {
        return;
    }

    return grant.specifications.map((block) => {
        const formattedData = {
            title: block.sfTitle,
            description: block.label
        };

        if (block.modalTitle && block.modalContent) {
            formattedData.moreInfo = {
                title: block.modalTitle,
                content: block.modalContent,
                isNearlyClosed: block.isNearlyClosed || false
            };
        }

        return formattedData;
    });
}
