<template>
    <sf-sticker>
        {{ $t('title') }}
    </sf-sticker>
</template>

<script>
import SfSticker from '~/patterns/atoms/sticker/sticker';

export default {
    components: {
        SfSticker
    },
};
</script>

<i18n>
{
    "nl": {
        "title": "nieuw"
    },
    "en": {
        "title": "new"
    }
}
</i18n>
