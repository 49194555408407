<template>
    <div
        v-if="specs && specs.length"
        class="sf-specs"
    >
        <div
            v-for="(spec, index) in filteredSpecs"
            :key="index"
            class="sf-specs__item"
        >
            <sf-specification
                :title="spec.title"
                :description="spec.description"
                :more-info="spec.moreInfo"
            />
        </div>
    </div>
</template>

<script>
import SfSpecification from './specification';

export default {
    components: {
        SfSpecification
    },

    props: {
        specs: {
            type: Array,
            default: null
        }
    },

    computed: {
        filteredSpecs() {
            return this.specs.filter(spec => (spec.title));
        }
    }
};
</script>

<style lang="less" src="./specs-list.less" />
