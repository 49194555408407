<template>
    <div class="sf-sticker">
        <h2><slot /></h2>
    </div>
</template>

<script>
export default {

};
</script>

<style lang="less" src="./sticker.less"></style>
