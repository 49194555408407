<template>
    <sf-sticker class="sf-sticker--additional">
        {{ $t('title') }}
    </sf-sticker>
</template>

<script>
import SfSticker from '~/patterns/atoms/sticker/sticker';

export default {
    components: {
        SfSticker
    },
};
</script>

<i18n>
{
    "nl": {
        "title": "sluit bijna"
    },
    "en": {
        "title": "nearly closed"
    }
}
</i18n>
