<template>
    <div class="panel">
        <slot />
    </div>
</template>

<script>
export default {

};
</script>

<style lang="less" src="./panel.less" />
