<template>
    <sf-basic-template v-if="grant">
        <template v-slot:default>
            <sf-hero
                class="sf-hero--grant"
                :title="grant.title"
                :images="grant.heroImages"
            >
                <template v-slot:stickers>
                    <sf-sticker-nearly-closed
                        v-if="grant.isNearlyClosed"
                    />
                    <sf-sticker-new
                        v-if="grant.isNew"
                    />
                </template>
            </sf-hero>
            <sf-lead-text-section
                v-if="grant.shortDescription"
                :block="{
                    wysiwyg: grant.shortDescription
                }"
            />

            <sf-section v-if="specificationData">
                <sf-specs-list :specs="specificationData" />
            </sf-section>

            <sf-accordion-section
                v-if="grant.accordion && grant.accordion.length"
                :block="{
                    title: $t('title.important-info'),
                    accordion: grant.accordion
                }"
            />

            <sf-section :layout="globalVideo ? 'video' : 'stappenplan'">
                <template v-slot:default>
                    <sf-step-list
                        :steps="grant.steps"
                    />
                </template>

                <template v-slot:aside>
                    <sf-discover-card
                        v-if="globalVideo"
                        card-type="video"
                        orientation="portrait"
                        :image="globalVideo.featuredImage && globalVideo.featuredImage.length > 0 ? globalVideo.featuredImage[0] : undefined"
                        :title="globalVideo.title"
                        :vimeo-url="globalVideo.vimeoUrl"
                        :accessible-vimeo-url="globalVideo.accessibleVimeoUrl"
                    />

                    <sf-discover-card
                        v-else-if="tips && tips.length"
                        card-type="tips"
                        orientation="portrait"
                        title="Hoe kom je tot een overtuigende aanvraag?"
                        :tips="tips.map(tip => tip.description)"
                    />
                </template>
            </sf-section>

            <sf-section>
                <sf-panel>
                    <p class="lead">
                        <template v-if="grant.type === 'open-call'">
                            {{ $t('cta.description.open-call') }}
                        </template>
                        <template v-else>
                            {{ $t('cta.description.grant') }}
                        </template>
                    </p>

                    <sf-button
                        :href="grant.applicationUrl"
                        element="a"
                        data-ga-category="start-aanvraag"
                        :data-ga-action="grant.applicationUrl"
                        :data-ga-label="$route.path"
                        class="dn-button--large"
                        style="margin-top: .5em;"
                        target="_blank"
                    >
                        <template v-slot:pre><sf-icon icon="arrow-right" /></template>
                        <template v-slot:default>{{ $t('cta.label') }}</template>
                    </sf-button>

                    <sf-media>
                        <template v-slot:body>
                            <span class="sf-media__title">{{ grant.contactTitle || $t('contact.title') }}</span>
                            <div v-if="grant.contactInformation" v-html="grant.contactInformation" />  <!-- eslint-disable-line -->
                            <div v-if="contactPerson">
                                <i18n path="contact.text" tag="p">
                                    <template v-slot:faq>
                                        <nuxt-link :to="$i18n.locale === 'nl' ? '/veelgestelde-vragen' : '/en/faq'">{{ $t('contact.faq') }}</nuxt-link>
                                    </template>
                                    <template v-slot:email>
                                        <a :href="`mailto:${ contactPerson.email}`">{{ contactPerson.title }}</a>
                                    </template>
                                    <template v-slot:phone>
                                        <a :href="`tel:${ contactPerson.phone}`">{{ contactPerson.phone }}</a>
                                    </template>
                                </i18n>
                            </div>
                        </template>
                    </sf-media>
                </sf-panel>
            </sf-section>

            <!-- <sf-section>
                <ul class="list--clean">
                    <li>
                        <sf-button
                            element="nuxt-link"
                            :to="localePath('grants')"
                            class="dn-button--link"
                        >
                            <template v-slot:default>{{ $t('link.back-to-index') }}</template>
                            <template v-slot:after>
                                <sf-icon icon="arrow-right" />
                            </template>
                        </sf-button>
                    </li>
                </ul>
            </sf-section> -->
        </template>

        <template
            v-if="grant.tags && grant.tags.length > 0"
            v-slot:related
        >
            <sf-related-items :entry="grant" />
        </template>
    </sf-basic-template>
</template>

<script>
import { isNull } from 'lodash';

import query from '~/graphql';
import getGrant from '~/graphql/queries/grants/getGrant.graphql';

import { formatSpecificationData } from '~/helpers/grant';

import CollapseMixin from '~/mixins/collapse';
import { NavigationVisible } from '~/mixins/navigation';

import SfBasicTemplate from '~/patterns/templates/basic.vue';
import SfRelatedItems from '~/patterns/organisms/related-items/related-items';
import SfHero from '~/patterns/organisms/hero/hero';
import SfSection from '~/patterns/organisms/section/section';
import SfLeadTextSection from '~/patterns/organisms/content-blocks/blocks/lead-text';
import SfSpecsList from '~/patterns/molecules/specs-list/specs-list.vue';
import SfMedia from '~/patterns/molecules/media/media.vue';
import SfAccordionSection from '~/patterns/organisms/content-blocks/blocks/accordion';
import SfStepList from '~/patterns/molecules/step-list/step-list';
import SfPanel from '~/patterns/molecules/panel/panel';
import SfDiscoverCard from '~/patterns/molecules/discover-card/discover-card.vue';
import SfButton from '~/patterns/atoms/button/button';
import SfIcon from '~/patterns/atoms/icon/icon';
import SfStickerNew from '~/patterns/atoms/sticker/presets/new.vue';
import SfStickerNearlyClosed from '~/patterns/atoms/sticker/presets/nearly-closed.vue';

export default {
    components: {
        SfBasicTemplate,
        SfRelatedItems,
        SfHero,
        SfSection,
        SfLeadTextSection,
        SfMedia,
        SfAccordionSection,
        SfSpecsList,
        SfStepList,
        SfPanel,
        SfDiscoverCard,
        SfIcon,
        SfButton,
        SfStickerNew,
        SfStickerNearlyClosed
    },

    mixins: [
        CollapseMixin,
        NavigationVisible
    ],

    async asyncData(context) {
        const slug = context.route.params.slug;

        // query expects a `$i18n` property within the context.
        const contextClone = { ...context, $i18n: context.i18n };
        const result = await query.call(contextClone, getGrant, { slug });

        if (isNull(result)) {
            return context.error({ statusCode: 404, message: 'error.message.404' });
        }

        return {
            grant: result.data.entry
        };
    },

    data() {
        return {
            grant: null
        };
    },

    head() {
        if (this.grant && this.grant.seo) {
            return this.$seo.getMetaData(this.grant.seo);
        }
    },

    computed: {
        globalVideo() {
            if (!this.grant || !this.grant.globalVideo || !this.grant.globalVideo[0]) {
                return;
            }

            return this.grant.globalVideo[0];
        },

        tips() {
            if (!this.grant || !this.grant.globalTips || !this.grant.globalTips[0]) {
                return;
            }

            return this.grant.globalTips[0].tips || [];
        },

        specificationData() {
            return formatSpecificationData(this.grant);
        },

        contactPerson() {
            if (!this.grant || !this.grant.contactPerson || !this.grant.contactPerson[0]) {
                return;
            }

            return this.grant.contactPerson[0];
        }
    },

    nuxtI18n: {
        paths: {
            nl: '/subsidies/:slug',
            en: '/grants/:slug'
        }
    }
};
</script>

<i18n>
{
    "nl": {
        "title.important-info": "Belangrijke info",
        "cta.description.grant": "Wil je subsidie aanvragen bij deze regeling?",
        "cta.description.open-call": "Wil je subsidie aanvragen bij deze open oproep?",
        "cta.label": "Start je aanvraag",
        "contact.faq": "veelgestelde vragen",
        "contact.title": "Heb je vragen?",
        "contact.text": "Is er iets niet duidelijk? Stel je vraag dan per e-mail aan onze collega’s van de {email}. Je kan ook bellen met {phone}. Heb je een meer algemene vraag, bekijk dan eerst de {faq}.",
        "link.back-to-index": "Bekijk andere subsidies"
    },
    "en": {
        "title.important-info": "Important information",
        "cta.description.grant": "Do you want to apply for a subsidy under this grant scheme?",
        "cta.description.open-call": "Do you want to apply for a subsidy under this open call?",
        "cta.label": "Start your application",
        "contact.faq": "frequently asked questions",
        "contact.title": "Do you have any questions?",
        "contact.text": "If some of the information is not clear, email your question to our colleagues of the {email}. You can also call on {phone}. If you have a more general question, have a look at our {faq} first.",
        "link.back-to-index": "View other grants"
    }
}
</i18n>
